<script setup>
import iconAnticipation from "@/assets/img/icons/statements/anticipation.svg";
import iconCashOut from "@/assets/img/icons/statements/cash-out.svg";
import iconBankAccount from "@/assets/img/icons/statements/bank-account.svg";

const props = defineProps({
  icon: {
    type: String,
    // required: true,
    // validator: (value) => ["success"].includes(value),
  },
  type: {
    type: String,
    default: "anticipation",
    validator: (value) => ["anticipation", "bankAccounts", "cash-out", ""].includes(value),
  },
});

function getIcon(type) {
  let icon = {
    anticipation: iconAnticipation,
    cashOut: iconCashOut,
    bankAccounts: iconBankAccount,
  };

  return icon[type];
}
</script>

<template>
  <div>
    <span class="icon" :class="`type-${type}`">
      <img :src="getIcon(type)" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  background-color: var(--gray-10);
  width: var(--w-16);
  height: var(--h-16);
  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;

  margin: auto;

  border-radius: var(--radius-full);

  img {
    height: var(--h-20);
    width: var(--w-20);
  }
}

.icon-success {
  background-color: transparent;
}

.type-anticipation {
  background-color: var(--blue-500);
}

.type-cash-out {
  button {
    background-color: var(--primary-50);
  }
}
</style>
